import React, { useEffect, useState } from "react";
import TableLoader from "../../../../UI/Dashboard/TableLoader";
import DataView from "../../../../UI/Dashboard/DataView";
import TableContainer from "../../../../UI/Dashboard/TableContainer";
import { Helmet } from "react-helmet-async";
import ContentLayout from "../../../../Common/ContentLayout";
import EditButton from "../../../../UI/Dashboard/EditButton";
import DeleteButton from "../../../../UI/Dashboard/DeleteButton";
import JourneyAjax from "../../../../../helpers/JourneyAjax";
import PaginationContainer from "../../../../UI/Dashboard/PaginationContainer";
import Pagination from "../../../../UI/Dashboard/Pagination";
import { useSearchParams } from "react-router-dom";
import DataLoader from "../../../../UI/Dashboard/DataLoader";
import { toast } from "react-toastify";
import Popup from "../../../../UI/Dashboard/Popup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import FormGroup from "../../../../UI/Form/FormGroup";
import ErrorField from "../../../../UI/Form/ErrorField";
import Button from "../../../../UI/Form/Button";
import SearchIcon from "../../../../../assets/IconComponents/SearchIcon";
import Modal from 'react-modal';

export default function BrokerList() {
  const [open, setOpen] = useState(false);
  const [searchParams,setSearchParams] = useSearchParams();
  const [totalRow, setTotalRows] = useState(null);
  const [userToken, setUserToken] = useState(null);
  const [dataList, setDataList] = useState(null);
  const [loader, setLoader] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(searchParams.get("page"));
  const [FilterList,setFilterList] = useState([
    {
      label:"Not Approved",
      value:"notApproved"
    },
    {
      label:"Approved",
      value:"approved"
    },
    {
      label:"PAN",
      value:"panCard"
    },
    {
      label:"Adhar",
      value:"adharCard"
    },
    {
      label:"Cancelled Cheque",
      value:"cancelledCheque"
    },
    {
      label:"Signature",
      value:"signature"
    },
    {
      label:"Kyc Doc",
      value:"kycDoc"
    }
  ])
  const [screens,setScreens] = useState([{title:"Home",link:"/home"},{title:"Your Leads",link:"/leads"},{title:"Our Properties",link:"/projects"},{title:"Invoices & Reciepts",link:"/invoices"},{title:"Selected Property",link:"/selectedProperty"},{title:"Register",link:"/register"},{title:"Documentaion",link:"/documentation"},{title:"Login",link:"/login"}])
  const [activeFilters,setActiveFilters] = useState(['notApproved','panCard', 'adharCard', 'cancelledCheque', 'signature','kycDoc'])
  const [searchText,setSearchText] = useState("")
  const userValidToken = localStorage.getItem("userToken");
  const currentPerPage = 10;
  const [isOpen, setIsOpen] = useState(false);
  const [notificationFilter, setNotificationFilter] = useState([])
  const [title, setTitle] = useState("")
  const [body, setBody] = useState("")
   
  Modal.setAppElement('#root');
  const userValid = async (values, event) => {
    const data = {
      ...(values.slug && { slug: values.slug }),
      username: values.username,
    };

    const formData = new FormData();

    for (var key in data) {
      formData.append(key, data[key]);
    }

    const checkUser = await JourneyAjax({
      url: `/admin/${data.username}`,
      "x-api-key": "houseeazy-dev",
      "x-user": data.username,
      token: true,
    });

    if (checkUser && checkUser.status === 200) {
      toast.success("Continue your journey");
      setUserToken(checkUser);
      localStorage.setItem("userToken", checkUser.data.username);
      setOpen(false);
    }

    if (checkUser && checkUser.status === 403) {
      toast.error("Username is not valid");
    }
  };

  const SearchInput = ({ onClear, searchText, setSearchText,setSearchParams }) => {
    const [localtext,setLocaltext] = useState(searchText)
    return (
        <div className="d-flex rounded w-25" >
        <input
          type="text"
         placeholder="Search"
          className="flex-1 w-70 text-black  py-2 px-2"
          value={localtext}
          onChange={(e) => {
            setLocaltext(e.target.value)
          }}
        />
        <div onClick={()=>{setSearchText(localtext); setSearchParams("page","0");}} className="bg-slave rounded  text-black  cursor-pointer py-2 px-2 ">
        <SearchIcon  fillColor="#ffffff" width={22} height={22}  /></div>
         {/* {searchText && (
            <button className="btn" onClick={onClear}>
              <CloseIcon fillColor="#ffffff" width={22} height={22} className="mr-2" />
            </button>
          )} */}
      </div>
    );
  };

  const FilterComponent = ({ active, label, setActiveFilters }) => {
    return (
      <div onClick={()=>{ setSearchParams("page","0")}}>
        {FilterList.map((item) => (
          <button
            type="button"
            className={`list-inline-item mx-1 py-2 px-3 border-0 cursor-pointer rounded ${
              active && active.includes(item.value)
                ? "bg-main text-white"
                : "bg-slave text-white"
            }`}
            onClick={() => {
              setActiveFilters((prev) => {
                if (prev.includes(item.value)) {
                  if(item.value === "approved"){
                    if(prev.includes("notApproved")) prev = prev.filter((item)=> item !== "notApproved");
                  }else if(item.value === "notApproved"){
                    if(prev.includes("approved")) prev = prev.filter((item)=> item !== "approved");
                  }
                  return prev.filter((sub) => sub !== item.value);
                } else {
                  if(item.value === "approved"){
                    if(prev.includes("notApproved")) prev = prev.filter((item)=> item !== "notApproved");
                  }else if(item.value === "notApproved"){
                    if(prev.includes("approved")) prev = prev.filter((item)=> item !== "approved");
                  }
                  return [...prev, item.value];
                }
              });
            }}
          >
            {item.label}
          </button>
        ))}
      </div>
    );
  };
  useEffect(() => {
    const skip =
      searchParams.get("page") === 1
        ? 10
        : searchParams.get("page") * currentPerPage;
    const getResponse = setTimeout(function() {
      (async () => {
        setLoader(true);
        let filter ={
          limit:currentPerPage,
          offset:skip,
          order:["createdAt DESC"],
          where:{
        
          }
        }
        let URL = `/brokers`
        if(searchText || activeFilters.length){
          if(searchText){
            filter.where = {
              "or":[{name:{like:searchText,options:"i"}},{mobile:{like:searchText,options:"i"}}]
            }
          }
          else if(activeFilters.length){
            activeFilters.forEach((item=>{
              if(item==="approved"){
                return filter.where[item]={eq:true}
              }
              if(item==="notApproved"){
                item = "approved"
                return filter.where[item]={eq:false}
              }
              if(item==="kycDoc"){
                item = "kycDoc"
                return filter.where["and"]=[{"kycDoc":{"neq":null}}, {"kycDoc":{"neq":""}}]
              }
              filter.where[`documents.${item}`]={ne:null}
            }))
          }
        }
        const filterString = `?filter=${JSON.stringify(filter)}`;
        URL = URL.concat(filterString);
        const response = await JourneyAjax({
          url: URL,
          "x-api-key": "houseeazy-dev",
          "x-user": userValidToken || null,
          token: true,
        });

        if (response.status === 200) {
          setDataList(response.data.data.brokers);
          setTotalCount(response.data.data.totalCount);
          setTotalRows(response.data.data.totalCount / 10);
          setCurrentPage(searchParams.get("page"));
          setLoader(false);
        }
      })();
    }, 100);
    return () => {
      clearInterval(getResponse);
    };
  }, [searchParams.get("page"),activeFilters,searchText]);

  const sendNotification = async (values) =>{
    if(!body || !title || !values.workFlowId || notificationFilter.length == 0){
      toast.error("Some Fields are Empty please check");
    }else{
    let filter = {
      where: {}
    }
    const data = {
      title:title,
      body:body,
      workFlowId:values.workFlowId,
      data:{
        branchLink:values.branchLink,
        screen:values.screen
      }
    }
    if (notificationFilter.length) {
      notificationFilter.forEach((item => {
        if(item == "kycDoc"){
          return filter.where["and"]=[{"kycDoc":{"eq":null}}, {"kycDoc":{"eq":""}}]
        }
        filter.where[`documents.${item}`] = { eq: null }
      }))
    }
    const update = await JourneyAjax({
      url: `/brokers/pushNotification?filter=${JSON.stringify(filter)}`,
      "x-api-key": "houseeazy-dev",
      "x-user": userValidToken || null,
      method: "POST",
      data: data,
      // token: true,
    });
    if (update.data.error) {
      toast.error("Something Went Wrong Please Check");
    }
    if (update.status === 200) {
      toast.success(update.data.message)
      setIsOpen(false);
      setBody("")
      setTitle("")
      setNotificationFilter([])
    }
  }
  }
  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setBody("")
    setTitle("")
    setNotificationFilter([])
  };

  const handleTitle = (e) =>{
    const msg = e.target.value
     if(msg.length < 50){
      setTitle(msg)
     }
  }

  const handleBody = (e) =>{
    const msg = e.target.value
    if(msg.length < 250){
      setBody(msg)
     }
  }

  const handleCheckboxChange = (e) =>{
    const filterName = e.target.name
    if (notificationFilter.includes(filterName)) {
      const filterData = notificationFilter.filter((sub) => sub !== filterName);
      setNotificationFilter(filterData)
    } else {
      const filterData = [...notificationFilter,filterName];
      setNotificationFilter(filterData)
    }
  }
  
  return (
    <>
      <ContentLayout
        title="Brokers"
        addNew="/admin/property-journey/broker/add-broker"
      >
        <div className="row">
          <Helmet>
            <title>Brokers</title>
          </Helmet>
          <div className="d-flex w-100 justify-content-between align-items-center pb-3">
          <SearchInput setSearchParams={setSearchParams} searchText={searchText} setSearchText={setSearchText} onClear={()=>{setSearchText("")}}/>
          <FilterComponent active={activeFilters} setActiveFilters={setActiveFilters}/>
          </div>
          {loader ? (
            <DataLoader />
          ) : (
            <>
              <div style={{ textAlign: 'right'}}>
                    <input onClick={openModal} value="Send notification" className="btn btn-primary" />
                    <Modal
                      isOpen={isOpen}
                      onRequestClose={closeModal}
                      contentLabel="Notification Popup"
                    >
                      <div className="container">
                      <div className="md-col-6">
                      <div style={{textAlign:'right'}}> 
                        <button onClick={closeModal}><i className="fas fa-times" style={{"font-size":"24px"}}></i></button>
                      </div>
                        <center><h1>Send notification</h1></center>
                        <div className='row d-flex align-items-center, justify-content-center'>
                          <div className='col-md-6'>
                          <Formik
                                initialValues={{
                                  title: "",
                                  body: "",
                                  branchLink:"",
                                  workFlowId:""
                                }}
                                onSubmit={(values, event) => {
                                  sendNotification(values, event);
                                }}
                              >
                                <Form>
                            <div className="row">
                              <div className="col-md-12">
                            <FormGroup label="Title" required>
                              <Field type="text" onChange={handleTitle} placeholder='Title' name="title" value={title}  className='form-control' />
                              <ErrorField><ErrorMessage name="title" /></ErrorField>
                            </FormGroup>
                            </div>
                            <div className="col-md-12" >
                            <FormGroup label="Body" required>
                              <Field type="text" placeholder='Body' onChange={handleBody} name="body" value={body}  className='form-control' />
                              <ErrorField><ErrorMessage name="body" /></ErrorField>
                            </FormGroup>
                            </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                  <FormGroup label="Branch Link" >
                                    <Field type="text" placeholder='Branch Link' name="branchLink"  className='form-control' />
                                    <ErrorField><ErrorMessage name="branchLink" /></ErrorField>
                                  </FormGroup>
                                  </div>
                                <div className="col-md-12">
                                  <FormGroup label="Screen">
                                    <Field
                                      as="select"
                                      name="screen"
                                      className="form-select"
                                    >
                                      <option value="">Select</option>
                                      {screens && screens.map((item)=>(
                                          <option value={item.link}>{item.title}</option>
                                      ))}
                                    </Field>
                                    <ErrorField>
                                      <ErrorMessage name="microMarket" />
                                    </ErrorField>
                                  </FormGroup>
                                </div>
                                <div className="col-md-12">
                                  <FormGroup label="Work Flow" required>
                                    <Field
                                      as="select"
                                      name="workFlowId"
                                      className="form-select"
                                    >
                                      <option value="">Select</option>
                                      <option value="push-notifications">Push Notifications</option>
                                    </Field>
                                    <ErrorField>
                                      <ErrorMessage name="workFlowId" />
                                    </ErrorField>
                                  </FormGroup>
                                </div>
                              <div className="col-md-12 required">
                                <label className="form-label text-start col-form-label">Broker Filter</label><br/>
                                <div className="d-flex justify-content-between">
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="panCard"
                                    onChange={handleCheckboxChange}
                                  />
                                  <label className="form-check-label" htmlFor="panCard">Pan Card</label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="adharCard"
                                    onChange={handleCheckboxChange}
                                  />
                                  <label className="form-check-label" htmlFor="adharCard">Adhar Card</label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="cancelledCheque"
                                    onChange={handleCheckboxChange}
                                  />
                                  <label className="form-check-label" htmlFor="cancelledCheque">Cancelled Cheque</label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="signature"
                                    onChange={handleCheckboxChange}
                                  />
                                  <label className="form-check-label" htmlFor="signature">Signature</label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="gst"
                                    onChange={handleCheckboxChange}
                                  />
                                  <label className="form-check-label" htmlFor="gst">GST</label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="kycDoc"
                                    onChange={handleCheckboxChange}
                                  />
                                  <label className="form-check-label" htmlFor="kycDoc">KycDoc</label>
                                </div>
                                </div>
                              </div>
                              </div>
                              <br/>
                              <div className="width-100% justify-content-center d-grid">
                                <Button type="submit">Send</Button>
                              </div>
                            </Form>
                         </Formik>
                          </div>
                        </div>
                      </div>
                      </div>
                    </Modal>
                  </div>
              <DataView title="Brokers List">
                <TableContainer>
                  <thead>
                    <tr>
                      <th className="text-start">Name</th>
                      <th className="text-center">Email</th>
                      <th className="text-center">Firm Name</th>
                      <th className="text-center">Mobile</th>
                      <th className="text-center">Approved</th>
                      <th className="text-center">PAN, Adhar, CC, Sign, kycDoc</th>
                      <th className="text-center">Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataList == null ? (
                      <TableLoader cols={6} />
                    ) : dataList.length === 0 ? (
                      <tr>
                        <td colSpan={6} className="text-center">
                          Data not available
                        </td>
                      </tr>
                    ) : (
                      dataList.map((data, index) => (
                        <tr key={data.id}>
                          <td className="text-start text-capitalize">
                            {data.name}
                          </td>
                          <td className="text-center">{data.email}</td>
                          <td className="text-center">{data.firmName}</td>
                          <td className="text-center">{data.mobile}</td>
                          <td className="text-center">{data.approved ? 'True' : 'False'}</td>
                          <td className="text-center">

                            <tr className="d-flex d-flex justify-content-center"><td className="d-flex justify-content-between mr-2"><span></span> {data.documents && data.documents.panCard ? <span className="text-success">✅</span> : <span>❌</span>}</td>
                              <><td className="d-flex justify-content-between mr-2"><span></span> {data.documents && data.documents.adharCard ? <span className="text-success">✅</span> : <span>❌</span>}</td></>
                              <><td className="d-flex justify-content-between mr-2"><span></span> {data.documents && data.documents.cancelledCheque ? <span className="text-success">✅</span> : <span>❌</span>}</td></>
                              <><td className="d-flex justify-content-between mr-2"><span></span> {data.documents && data.documents.signature ? <span className="text-success">✅</span> : <span>❌</span>}</td></>
                              <><td className="d-flex justify-content-between mr-2"><span></span> {data.kycDoc ? <span className="text-success">✅</span> : <span>❌</span>}</td></>
                            </tr>

                          </td>
                          <td className="text-center">
                            {userValidToken ? (
                              <EditButton>{`/admin/property-journey/broker/${data.id}`}</EditButton>
                            ) : (
                              <div
                                className="btn btn-primary"
                                onClick={() => setOpen(true)}
                              >
                                <i className="fas fa-pencil-alt"></i>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </TableContainer>
                <PaginationContainer count={totalCount}>
                  <Pagination
                    totalPage={totalRow}
                    url="/admin/property-journey/broker"
                    currentPage={searchParams.get("page")}
                  />
                </PaginationContainer>
              </DataView>
            </>
          )}
        </div>
      </ContentLayout>
      <Popup open={open} close={setOpen}>
        <Formik
          initialValues={{
            username: "",
          }}
          onSubmit={(values, event) => {
            userValid(values, event);
          }}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <FormGroup label="Username" required>
                <Field
                  placeholder="Username"
                  name="username"
                  className="form-control"
                />
                <ErrorField>
                  <ErrorMessage name="username" />
                </ErrorField>
              </FormGroup>

              <div className="d-grid">
                <Button type="submit">Enter User ID</Button>
              </div>
            </Form>
          )}
        </Formik>
      </Popup>
    </>
  );
}